// styles
import { CircularProgress } from '@mui/material';
import './generalLoadingPage.css';

export default function GeneralLoadingPage(){
    return(
        <div className="general-loading-page">
            <CircularProgress size={30} style={{ color:'#FFE74C' }}/>
            <p>Loading....Please wait</p>
        </div>
    )
}