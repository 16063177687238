// styles
import './loginForm.css';
// react states
import { useState } from 'react';
// react-router-dom
import { Link, useNavigate } from 'react-router-dom';
// custom hook(s)
import { useLogin } from '../../../../Hooks/useLogin';
// custom components
import ExternalAuthenticationComponent from '../../ExternalAuthenticationComponents/externalAuthenticationComponent';
// mui icons
import CheckIcon from '@mui/icons-material/Check';
import { CircularProgress } from '@mui/material';

export default function LoginForm(){

    // Hook to use for logging in
    const { error, loggingIn, logUserIn } = useLogin();
    
    // Routing
    const authNavigate = useNavigate();

    // To toggle the visibility of the password
    const [visible, setVisible] = useState(false);
    const toggleVisibility = ()=>{
        if(visible === false){
            setVisible(true);
        }else{
            setVisible(false);
        }
    }

    // To handle the submit events
    const [mail, setMail] = useState('');
    const [pass, setPass] = useState('');
    
    // custom login
    const handleLogin = (e)=>{
        e.preventDefault();
        // console.log({
        //     mail,
        //     pass
        // })
        logUserIn(mail, pass);
    }

    const navigateToSignUp = ()=>{
        authNavigate('/signup');
    }

    return(
        <>
        <div className="external-auth-provider-container">
            <p className='external-auth-provider-heading'>Sign in with</p>
            <ExternalAuthenticationComponent/>
            <div className="auth-form-divider">
                OR
            </div>
        </div>

        <form className='user-login-input-form'
            onSubmit={(e)=>handleLogin(e)}
        >
            <input 
                className='email-input'
                type="email"
                required
                placeholder='Enter your email'
                onChange={(e)=>setMail(e.target.value)}
                value={mail}
                name='user-mail'
            />
            <div className="password-box">
                <input type={ visible === true ? "text" : "password" } 
                       required
                       placeholder='Enter your password'
                       onChange={(passEvent)=>setPass(passEvent.target.value)}
                       value={pass}
                       name='user-password'
                />
                <div className="visibility-box"
                     onClick={()=>toggleVisibility()}
                >
                    <div className={visible === true ? "visibility-tick-container active" : "visibility-tick-container"}>
                    {
                        visible === true ? <CheckIcon/> : null
                    }
                    </div>
                    <p className='visibility-txt'>Show password</p>
                </div>
            </div>
            {
                loggingIn !== true && <button className='cta'>
                                        Login
                                        </button>
            }
            {
                loggingIn === true && <CircularProgress size={24} style={{ 'color':'#FFE74C', 'marginBottom':'30px' }}/>
            }
        </form>
        { error && <p>{error}</p> }
        {
            loggingIn !== true && 
            <>
                <div className="forgot-password-container">
                    <Link to={`/password_forget`} style={{ 'textDecoration':'none' }}>
                        <h4>Forgot password?</h4>
                    </Link>
                    {/* <Link to={`/password_reset`} style={{ 'textDecoration':'none' }}>
                        <h4>Forgot password?</h4>
                    </Link> */}
                </div>
                <div className="other-option-prompt">
                    <p>Don't have an account ? </p>
                    <h4 onClick={()=>navigateToSignUp()}>
                        Create account
                    </h4>
                </div>
            </>
        }
        </>
    )
}