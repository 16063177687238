// styles
import './externalAuthenticationComponent.css';
// brand icons
import gauthIcon from '../../../assets/Logo/Google-Logo/Web (mobile + desktop)/png@4x/light/web_light_rd_na@4x.png';
// import fauthIcon from '../../../assets/Logo/Facebook Brand Asset Pack/Logo/Primary Logo/Facebook_Logo_Primary.png';
import { useLogin } from '../../../Hooks/useLogin';


export default function ExternalAuthenticationComponent(){

    // custom hook for logging in
    const { logInWithGoogle } = useLogin();

    // Google login
    const handleGoogleLogin = ()=>{
        logInWithGoogle();
    }

    // const handleFacebookAuth = ()=>{
    //     loginWithFacebook();
    // }

    return(
        <div className="auth-providers-container">
            <button className="auth-provider"
                    onClick={()=>handleGoogleLogin()}
            >
                <img src={gauthIcon} alt="google authentication"/>
            </button>
            {/* <button className='auth-provider'
                    onClick={()=>handleFacebookAuth()}
            >
                <img src={fauthIcon} alt="facebook authentication"/>
            </button> */}
        </div>
    );
}