// styles
import './homepage.css';

// Ad component
// import AdComponent from '../../Ads/AdComponent';

// Components
import HottestGameBanner from '../../Components/HomepageComponents/TrendingGameBanner/trendingGameBanner';
import TrendingReviewArea from '../../Components/HomepageComponents/TrendingReview/trendingReviewArea';
import TrendingForumArea from '../../Components/HomepageComponents/TrendingForums/trendingForumArea';
import EditorsPick from '../../Components/HomepageComponents/EditorsPick/editors_pick';

// assets
import mainLogoImg from '../../assets/Logo/LOGO-2.png';

// SEO
import { Helmet } from 'react-helmet';

export default function Homepage(){

    return(
        <>
        <div className='homepage-container'>
            <Helmet>
                {/* {seoInfo && <title>{seoInfo.name}</title>} */}
                <meta name='tagline' content={'For the gamers. By the gamers.'}/>
                {/* { seoInfo && <meta name='description' content={`${seoInfo.name}, ${seoInfo.publisher_name}`}/> } */}
                <meta name='keywords' content={'Gamer, Bros, TGB, games, game, gaming profile, reviews, forums, game review, game forums, game discussions, game ratings, game search, review, search, forum, ratings'}/>
                <meta name='og:image' content={mainLogoImg}/>
            </Helmet>
            <div className="homepage-ad-components-1">
            </div>
            <div className="homepage-contents">
                <HottestGameBanner/>
                <div className="vertical-space-div"></div>
                <TrendingForumArea/>
                <div className="vertical-space-div"></div>
                <TrendingReviewArea/>
                <div className='vertical-space-div'></div>
                <EditorsPick/>
                <div className="vertical-space-div"></div>
            </div>
            <div className="homepage-ad-components-2">
            </div>
            {/* 
            <RandomReview/>
            <EditorsPick/>
            <RecentReleases/> */}
        </div>
        </>
    )
}