/* eslint-disable react-hooks/exhaustive-deps */
// styles
import { useEffect, useState } from 'react';
import './dateOfBirthInput.css';

export default function DateOfBirthField({ setDOB }){

    const [openSelectYear, setOpenSelectYear] = useState(false);
    const [openSelectMonth, setOpenSelectMonth] = useState(false);
    const [openSelectDates, setOpenSelectDates] = useState(false);

    const dobMonths = [
        'January', 
        'February', 
        'March', 
        'April', 
        'May', 
        'June', 
        'July', 
        'August', 
        'September', 
        'October', 
        'November', 
        'December'
    ];

    const [selectedDOBMonth, setSelectedDOBMonth] = useState(0);

    const [DOByears, setDOBYears] = useState([]);
    const [selectedDOBYear, setSelectedDOBYear] = useState(new Date().getFullYear());
    useEffect(()=>{
        // The automatic dob selection
        setDOB(`01-01-${selectedDOBYear}`)
        setSelectedDOBMonth(0)
        setDOBYears([]);
        const dobYears = [];
        const currentYear = new Date().getFullYear();
        // console.log(currentYear);
        for(var i=1960; i<=currentYear; i+=1){
            // setDOBYears(DOByears => [i, ...DOByears]);
            dobYears.push(i);
        }
        setDOBYears(dobYears);
    },[])

    // console.log(DOByears);

    const [DOBdates, setDOBdates] = useState([]);
    const [selectedDOBDate, setSelectedDOBDate] = useState(1);
    useEffect(()=>{
        const tempDOB = [];
        setDOBdates([]);
        if(selectedDOBMonth === 0 || selectedDOBMonth === 2
            || selectedDOBMonth === 4 || selectedDOBMonth === 6 ||
        selectedDOBMonth === 7 || selectedDOBMonth === 9 ||
        selectedDOBMonth === 12){
            for(var j=1; j<=31; j++){
                tempDOB.push(j);
            }
        }

        if(selectedDOBMonth === 3 || selectedDOBMonth === 5
            || selectedDOBMonth === 8 || selectedDOBMonth === 10){
            for(var k=1; k<=31; k++){
                tempDOB.push(k);
            }
        }

        // Special cases for february
        if(selectedDOBMonth === 1){
            // Check for leap year
            if ((selectedDOBYear % 4 === 0) && ((selectedDOBYear % 100 !== 0) || (selectedDOBYear % 400 === 0))) {
                for(var l=1; l<=29; l++){
                    tempDOB.push(l);
                }
                if(selectedDOBDate > 29){
                    setSelectedDOBDate(29);
                }
            }else{
                for(var p=1; p<=28; p++){
                    tempDOB.push(p);
                }
                if(selectedDOBDate > 28){
                    setSelectedDOBDate(28);
                }
            }
        }

        setDOBdates(tempDOB);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedDOBMonth, selectedDOBYear])

    // console.log(DOBdates);

    const toggleSelectYear = ()=>{
        if(openSelectYear === false){
            setOpenSelectYear(true);
            setOpenSelectMonth(false);
            setOpenSelectDates(false);
        }else{
            setOpenSelectYear(false);
        }
    }

    const selectYear = (y)=>{
        setSelectedDOBYear(y);
        if(selectedDOBMonth+1 < 10){
            setDOB(`${selectedDOBDate}-0${selectedDOBMonth+1}-${y}`);  
        }else{
            setDOB(`${selectedDOBDate}-${selectedDOBMonth+1}-${y}`);
        }
        toggleSelectYear();
        setOpenSelectDates(false);
        setOpenSelectMonth(false);
    }

    const toggleSelectMonth = ()=>{
        if(openSelectMonth === false){
            setOpenSelectMonth(true);
            setOpenSelectYear(false);
            setOpenSelectDates(false);
        }else{
            setOpenSelectMonth(false);
        }
    }

    const selectMonth = (m)=>{
        setSelectedDOBMonth(m);
        if(m+1 >= 10){
            setDOB(`${selectedDOBDate}-${m+1}-${selectedDOBYear}`);
        }else{
            setDOB(`${selectedDOBDate}-0${m+1}-${selectedDOBYear}`);
        }
        toggleSelectMonth();
        setOpenSelectYear(false);
        setOpenSelectDates(false);
    }

    const toggleSelectDates = ()=>{
        if(openSelectDates === false){
            setOpenSelectDates(true);
            setOpenSelectMonth(false);
            setOpenSelectYear(false);
        }else{
            setOpenSelectDates(false);
        }
    }

    const selectDates = (d)=>{
        setSelectedDOBDate(d);
        if(selectedDOBMonth+1 >= 10){
            setDOB(`${d}-${selectedDOBMonth+1}-${selectedDOBYear}`);
        }else{
            setDOB(`${d}-0${selectedDOBMonth+1}-${selectedDOBYear}`);
        }
        toggleSelectDates();
        setOpenSelectYear(false);
        setOpenSelectMonth(false);
    }

    return(
        <div className="date-of-birth-container">
            <p className='dob-label'>Date of Birth</p>
            <div className="dob-fields">
                <div className="date-field">
                    <div className="selected-date-area"
                         onClick={()=>toggleSelectDates()}
                    >
                        {selectedDOBDate}
                    </div>
                    {
                        openSelectDates === true &&
                        <div className="dob-date-selection-container">
                        {
                            DOBdates && DOBdates.length > 0 &&
                            DOBdates.map((current_date)=>(
                                // monthIndex > 0 && 
                                <div key={current_date} className="dob-date-tile"
                                    onClick={()=>selectDates(current_date)}
                                >
                                    { current_date }
                                </div>
                            ))
                        }
                        </div>
                    }
                </div>
                <div className="month-field">
                    <div className="selected-month-area"
                         onClick={()=>toggleSelectMonth()}
                    >
                        {dobMonths[selectedDOBMonth]}
                    </div>
                    {
                        openSelectMonth === true &&
                        <div className="dob-month-selection-container">
                        {
                            dobMonths && dobMonths.length > 0 &&
                            dobMonths.map((current_month, monthIndex, )=>(
                                // monthIndex > 0 && 
                                <div key={monthIndex} className="dob-month-tile"
                                    onClick={()=>selectMonth(monthIndex)}
                                >
                                    {`${current_month}(${monthIndex+1})`}
                                </div>
                            ))
                        }
                        </div>
                    }
                </div>
                <div className="year-field">
                    <div className="selected-year-area"
                         onClick={()=>toggleSelectYear()}
                    >
                        {selectedDOBYear}
                    </div>
                    {
                        openSelectYear === true &&
                        <div className="dob-year-selection-container">
                        {
                            DOByears && DOByears.length > 0 &&
                            DOByears.map((current_year)=>(
                                <div key={current_year} className="dob-year-tile"
                                     onClick={()=>selectYear(current_year)}
                                >
                                    { current_year }
                                </div>
                            ))
                        }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}