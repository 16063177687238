// react states
import { useState } from 'react';
// firebase
import { auth, db } from '../Firebase/firebase';
import { setDoc, doc, updateDoc } from 'firebase/firestore';

// react-router-dom
import { useNavigate } from 'react-router-dom';

// react redux
import { useSelector, useDispatch } from 'react-redux';
import { login, selectUser } from '../Features/userSlice';
import { selectUserData, setPrivateData } from '../Features/userPrivateDataSlice';
import { updateEmail } from 'firebase/auth';

export const useOnboardingUser = ()=>{
    const [onboardingProgress, setOnboardingProgress] = useState(false);
    const [onboardingStatus, setOnboardingStatus] = useState(null);
    const [onboardingError, setOnboardingError] = useState(null);

    const userNav = useNavigate();
    const userDispatch = useDispatch();
    const user = useSelector(selectUser);
    const userData = useSelector(selectUserData);

    const getUserOnboard = async(username, dob)=>{
        setOnboardingProgress(true);
        if(user === null){
            setOnboardingProgress(false);
            setOnboardingStatus('fail');
            return;
        }
        else{
            await updateEmail(auth.currentUser, user.email)
            await setDoc(doc(db, `Users/${user.uid}/PrivateInfo`,"userInfo"),{
                'DOB':dob,
                'favourites':[],
                'game_collection':[],
                'currently_playing':[],
                'games_reviewed':[],
                'country':'',
                'playlist':[],
                'wishlist':[]
            }).then(async()=>{
                const userDocRef = doc(db,`Users`,`${user.uid}`);
                await updateDoc(userDocRef, {
                    "Name":username,
                    "isInDatabase":true
                }).catch((err)=>{
                    setOnboardingStatus('Something went wrong! Please try again after few minutes...');
                    setOnboardingError(err.message);
                    setOnboardingProgress(false);
                    return;
                })
            }).then((_)=>{
                setOnboardingProgress(false);
                setOnboardingStatus('Your account is successfully created');
                const currentUserObject = {
                    ...userData,
                    'Name':username,
                    'isInDatabase':true,
                    'isEmailVerified':user.isEmailVerified
                }
                userDispatch(setPrivateData(currentUserObject));
                userDispatch(login({
                    uid:user.uid,
                    isEmailVerified:user.isEmailVerified
                }))
                userNav(`/user_profile/${user.uid}`);
            }).catch((err)=>{
                setOnboardingStatus('Something went wrong! Please try again after few minutes...');
                setOnboardingError(err.message);
                setOnboardingProgress(false);
                return;
            })
        }
    }

    return { onboardingProgress, onboardingStatus, onboardingError, getUserOnboard };
}