// styles
import './gamerBroCheckBox.css';

export default function GamerBroCheckBox({ labelText, value, setValue }){
    
    const handleValueChange = ()=>{
        if(value === true){
            setValue(false);
        }else{
            setValue(true);
        }
    }
    
    return(
        <div className="gamer-bro-check-box">
            <button className={value === false ? "gamer-bro-check-box-input" : "gamer-bro-check-box-input active"}
                    onClick={()=>handleValueChange()}
            >
            </button>
            <p onClick={()=>handleValueChange()}>{labelText}</p>
        </div>
    );
}