/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './emailVerifyingComponent.css';
// react states
import { useEffect, useState } from 'react';
// firebase
import { auth } from '../../../Firebase/firebase';
import { applyActionCode } from 'firebase/auth';
// react router dom
import { useNavigate, useSearchParams} from 'react-router-dom';


export default function EmailVerifyingComponent(){

    const [verifying, setVerifying] = useState(false);
    const [verifyStatus, setVerifyStatus] = useState(null);

    const [ searchParams ] = useSearchParams();
    // console.log('mode => ',searchParams.get('mode'));
    // console.log('actionCode/oobCode => ',searchParams.get('oobCode'));

    // console.log('auth => ',auth);

    const authNavigate = useNavigate();

    useEffect(()=>{
        if(searchParams!==null && searchParams.get('mode')==='verifyEmail'){
            setVerifying(true);
            const actionCode = searchParams.get('oobCode');
            applyActionCode(auth, actionCode).then(()=>{
                setVerifying(false);
                setVerifyStatus('success');
            }).catch((err)=>{
                setVerifying(false);
                setVerifyStatus('fail');
            })
        }
        else if(searchParams!==null && searchParams.get('mode')==='resetPassword'){
            authNavigate('/password_reset',{
                state:{
                    'oobCode':searchParams.get('oobCode')
                }
            })
        }
    },[searchParams]);


    const sendToOnboarding = ()=>{
        authNavigate('/user_onboarding');
    }

    const failedVerificationTab = ()=>{
        authNavigate('/email_verification');
    }

    return(
        <div className="verifying-email-container">
        {
            verifying && <p className='verification-progress'>Just a moment...we are verifying you.</p>
        }
        {
            verifyStatus === 'success' && 
            <div className="verification-success">
                <p>Verification successful</p>

                <button className='after-verification-cta'
                        onClick={()=>sendToOnboarding()}
                >
                    Continue
                </button>
            </div>
        }
        {
            verifyStatus === 'fail' && 
            <div className="verification-success">
                <p>Verification failed</p>
                <button className='after-verification-cta'
                        onClick={()=>failedVerificationTab()}
                >
                    OK
                </button>
            </div>
        }
        </div>
    )
}