/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './gameReviewBox.css';
// react states
import { useEffect, useState } from 'react';
// react router dom
import { Link, useNavigate } from 'react-router-dom';
// hooks
import { useGetUserProfile } from '../../../Hooks/useGetUserProfile';
// custom component
import Sharing from '../../Sharing/sharing';
import DiscussionArea from '../../DiscussionAreaComponents/discussionArea';
// mui icons
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CommentIcon from '@mui/icons-material/Comment';

// to give reactions
import { useGiveReactions } from '../../../Hooks/Reactions/useGiveReactions';
import { CircularProgress, Dialog, DialogContent } from '@mui/material';
import { useDeleteReview } from '../../../Hooks/useDeleteReview';

const GameReviewBox = ({ review, currentUser, gameName }) => {

    const { getProfileDetails, userData } = useGetUserProfile();
    const currentUserData = useGetUserProfile();

    // to navigate to full review if needed
    const reviewNavigate = useNavigate();
    const navigateToFullReview = ()=>{
        reviewNavigate(`/full_review/${review.id}`);
    }

    // To navigate to user profile if needed
    const navigateToReviewUserProfile = ()=>{
        reviewNavigate(`/user_profile/${review.userID}`);
    }

    // To get the review in a particular word limit.
    const [reviewText, setReviewText] = useState(null);
    const [enableReadMore, setEnableReadMore] = useState(false);

    useEffect(()=>{
        getProfileDetails(review.userID);
        currentUserData.getProfileDetails(currentUser);

        if(review.review_text.length > 400){
            setReviewText(review.review_text.slice(0,400)+"...");
            setEnableReadMore(true);
        }else{
            setReviewText(review.review_text);
        }
    },[review])


    // To get reactions
    const { prevReaction, tempLikes, tempDislikes,
        handleClickLike, handleClickDislike } = useGiveReactions(
            'review',
            review.id,
            currentUser
        );

    // To share the review
    const [startSharingRev, setStartSharingRev] = useState(false);
    const openReviewSharingDialog = ()=>{
        setStartSharingRev(true);
    }
    const closeReviewSharingDialog = ()=>{
        setStartSharingRev(false);
    }

    // To open the comments section
    const [openRevComments, setOpenRevComments] = useState(true);
    const toggleRevBoxComments = ()=>{
        if(openRevComments === false)
            setOpenRevComments(true);
        else if(openRevComments === true)
            setOpenRevComments(false);
    }

    // To get the number of comments on the review
    const [commentsCount, setCommentsCount] = useState(0);

    // To delete the review
    const [openRevDeletionBox, setOpenRevDeletionBox] = useState(false);
    const handleOpenRevDeletionBox = ()=>{
        setOpenRevDeletionBox(true);
    }
    const handleCloseRevDeletionBox = ()=>{
        setOpenRevDeletionBox(false);
    }

    // Hook to use to delete the review
    const { deleting, deleteReview, success } = useDeleteReview();


    return(
        <div className='user-review-container' id={`${review.id}`}>
            <Dialog
                open={openRevDeletionBox}
                fullWidth
                maxWidth={'sm'}
            >
                <DialogContent className='game-rev-box-deletion-content'>
                    {
                        deleting === false && success === null &&
                        <>
                            <p>Are you sure you want to delete this review ?</p>
                            <div className="game-rev-del-box-action-row">
                                <button className="user-rev-del-btn"
                                        onClick={()=>deleteReview(review.id, review.gameID, currentUser)}
                                >
                                    Yes, I am sure
                                </button>
                                <button className="user-rev-del-btn secondary"
                                        onClick={()=>handleCloseRevDeletionBox()}                             
                                >
                                    No
                                </button>
                            </div>
                        </>
                    }
                    {
                        deleting === true && <CircularProgress size={16} style={{ color:'#FFE74C' }}/>
                    }
                    {
                        deleting === false && success === 'success' &&
                        <>
                            <p>Review deleted successfully.</p>
                            <button className="user-rev-del-btn"
                                    onClick={()=>handleCloseRevDeletionBox()}
                            >
                                OK
                            </button>    
                        </>
                    }
                    {
                        deleting === false && success === 'fail' &&
                        <>
                            <p>Oops!!! There seems to be some problem. Please try again later.</p>
                            <button className="user-rev-del-btn"
                                    onClick={()=>handleCloseRevDeletionBox()}
                            >
                                OK
                            </button>    
                        </>
                    }
                </DialogContent>
            </Dialog>
            {
                startSharingRev === true &&
                <Sharing
                    type={'review'}
                    sharingLink = {`https://thegamerbroz.com/full_review/${review.id}`}
                    extraText={reviewText}
                    startSharing={startSharingRev}
                    closeSharingDialog={closeReviewSharingDialog}
                />
            }
            <div className="user-score-area">
                {review.score}/10
            </div>
            <div className="user-details-area"
                 onClick={()=>navigateToReviewUserProfile()}
            >
                <div className="user-pfp-box">
                {
                    userData && userData!==null &&
                    userData.profile_picture !== null &&
                    <img src={userData.profile_picture} alt=""/>
                }
                {
                    userData && userData!==null &&
                    userData.profile_picture === null &&
                    <p>{userData.Name.substring(0,1).toUpperCase()}</p>
                }
                </div>
                {userData && userData !== null && <p className='user-pfp-name'>{userData.Name}</p>}
                <p className='review-date'>{review.date}</p>
            </div>
            <div className="user-review-text-area">
                <p>{reviewText}</p>
            </div>
            <div className="user-review-action-btns">
                <div className="reaction-rev-area"
                     onClick={()=>handleClickLike(review)}
                >
                    <ThumbUpIcon style={{ 'fontSize':'18px', 'color':(prevReaction.length > 0 && prevReaction[0]==='L') ? '#FFE74C' : '#F8F8F8' }}/>
                    <p style={{ 'color':(prevReaction.length > 0 && prevReaction[0]==='L') ? '#FFE74C' : '#F8F8F8' }}>{tempLikes}</p>
                </div>
                <div className="reaction-rev-area"
                     onClick={()=>handleClickDislike()}
                >
                    <ThumbDownIcon style={{ 'fontSize':'18px', 'color':(prevReaction.length > 0 && prevReaction[0]==='D') ? '#FFE74C' : '#F8F8F8' }}/>
                    <p style={{ 'color':(prevReaction.length > 0 && prevReaction[0]==='D') ? '#FFE74C' : '#F8F8F8' }}>{tempDislikes}</p>
                </div>
                <button className='rev-reply-btn'
                        onClick={()=>toggleRevBoxComments()}
                >
                    <CommentIcon style={{ 'marginRight':'5px', 'fontSize':'20px', 'color':(openRevComments === true) ? '#FFE74C' : '#F8F8F8' }}/>
                    <p style={{ 'textAlign':'left', 'color':(openRevComments === true) ? '#FFE74C' : '#F8F8F8' }}>{commentsCount}</p>
                {/* {
                    openRevComments === false ? 'Comment' : 'Close'
                } */}
                </button>
                {
                    enableReadMore === true &&
                    <button className='rev-reply-btn'
                            onClick={()=>navigateToFullReview()}
                    >
                        Read more
                    </button>
                }
                <button className='rev-reply-btn'
                        onClick={()=>openReviewSharingDialog()}
                >
                    Share
                </button>
                {
                    currentUser === review.userID &&
                    <button className='rev-reply-btn'
                            onClick={()=>handleOpenRevDeletionBox()}
                    >
                        Delete
                    </button>
                }
                {
                    currentUser !== null && 
                    <Link to={'/report'} style={{ textDecoration:'none' }}
                        state={{
                            'item': review,
                            'reporter': currentUser,
                            'type':'review'
                        }}
                    >
                        <button className='rev-reply-btn'>
                            Report
                        </button>
                    </Link>
                }
            </div>
            <div className="review-box-comments-area">
            {
                openRevComments === true &&
                review!== null &&
                review.id !== null &&
                <DiscussionArea discussionID={review.id} 
                                taggedGames={[review.gameID]}
                                section={'review'}
                                commentsCounter={setCommentsCount}
                                fullDoc={review}
                />
            }
            </div>
        </div>
    )
}

export default GameReviewBox;