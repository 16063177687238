/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './user_review_write_container.css';
// react states
import { useState, useEffect } from 'react';
// custom components
import UserReviewScoring from '../UserReviewWriteScoringUtility/user_review_write_scoring';
import UserReviewWriteTextInput from '../UserReviewWriteTextUtility/user_review_write_text_input';
import GamerBroCheckBox from '../../CTAButtons/Checkboxes/gamerBroCheckBox';
// custom hooks
import { useSetUserReview } from '../../../Hooks/useSetUserReview';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

export default function UserReviewWriteContainer({ userIDObject, userDataObject, gameDetails }){
    
    // for users navigation
    const userReviewWritePageNav = useNavigate();

    // FIRST THING FIRST, CHECK IF THE USER HAS ALREADY REVIEWED THIS GAME
    // IF YES, TAKE THEM TO THEIR FULL REVIEW PAGE ELSE STAY ON THIS PAGE.
    // variable to check for duplicacy
    const [checkDuplicacy, setCheckDuplicacy] = useState(false);

    const handleDuplicacyCheck = async()=>{
        if(userDataObject !== null && userDataObject !== undefined
            && gameDetails !== null && gameDetails !== undefined
        ){
            const reviewedGamesArray = userDataObject.games_reviewed;
            const isPresent = reviewedGamesArray.includes(gameDetails.id);
            
            if(isPresent === true){
                setCheckDuplicacy(true);
            }else{
                setCheckDuplicacy(false);
            }
        }
    }

    useEffect(()=>{
        handleDuplicacyCheck();
    },[userDataObject, gameDetails])

    // utility function to send user to the profile page
    const sendToUserProfile = ()=>{
        userReviewWritePageNav('/user_auth');
    }

    // variable for user text review input
    const [userRevText, setUserRevText] = useState('');

    // variable for user score review
    const [userReviewScore, setUserReviewScore] = useState(-1);

    // variable for confirming spoilers or not.
    const [hasSpoilers, setHasSpoilers] = useState(false);

    // hook to upload the user review
    const { uploadingProgress, uploadStatus, reviewUploadError, finalReviewId, setReviewUploadError, uploadReview } = useSetUserReview();

    const handleReviewUpload = async()=>{
        if(userIDObject === null || userIDObject === undefined){
            userReviewWritePageNav('/user_auth');
        }
        if(gameDetails === null || gameDetails === undefined){
            setReviewUploadError("There was a problem on our end. We're trying to fix it. Sorry for the inconvenience.");
        }
        if(userReviewScore === -1){
            setReviewUploadError('Please give a score to the game.');
        }
        else{
            uploadReview(userIDObject.uid, gameDetails.id, userRevText, userReviewScore, hasSpoilers);
        }
    }

    // To redirect the user to the full review page when the review is done.
    useEffect(()=>{
        if(uploadStatus === true && finalReviewId){
            userReviewWritePageNav(`/full_review/${finalReviewId}`)
        }
    },[uploadStatus])

    return(
        <div className="user-review-write-container">
        {
            checkDuplicacy === false &&
            <>
                <div className="user-review-write-text-box">
                {
                    userIDObject !== null && userDataObject !== null &&
                    <div className="user-review-write-pfp">
                    {
                        userDataObject.profile_picture === null && <p>{userDataObject.Name.substring(0,1).toUpperCase()}</p>   
                    }
                    {
                        userDataObject.profile_picture !== null && <img src={userDataObject.profile_picture} alt=""/>
                    }
                    </div>
                }
                <UserReviewWriteTextInput 
                    userReviewText={userRevText}
                    setUserReviewText={setUserRevText}
                />
                </div>
                <UserReviewScoring
                    userRevScore={userReviewScore}
                    setUserRevScore={setUserReviewScore}
                />
                <GamerBroCheckBox labelText={'Does you review contain spoilers?'}
                                value={hasSpoilers}
                                setValue={setHasSpoilers}
                />
                <div className="user-review-write-post-btn-div">
                {
                    uploadingProgress === false &&
                    <button className='user-review-write-post-btn'
                        onClick={()=>handleReviewUpload()}
                        disabled={uploadingProgress === true ? true : false}
                    >
                        Post
                    </button>
                }
                {
                    uploadingProgress === true &&
                    <CircularProgress size={16} style={{ color:'#FFE74C', fontWeight:'700' }}/>
                }
                {
                    uploadingProgress === false && uploadStatus === true &&
                    <>
                        <p className='user-review-present-1'>Thank you for your review.</p>
                        <p className='user-review-present-2'>Visit your profile to see the review written by you.</p>
                    </>
                }
                </div>
                {
                    reviewUploadError !== null && 
                    <div className="user-review-write-error-box">
                        <p>{reviewUploadError}</p>
                    </div>
                }
            </>
        }{
            checkDuplicacy === true &&
            <div className="user-review-write-already-present">
                <p className='user-review-present-1'>Looks like you have already written review for this game.</p>
                <p className='user-review-present-2'>Visit your profile to see the review written by you.</p>
                <button className='user-review-write-post-btn'
                        onClick={()=>sendToUserProfile()}
                >
                    Go to profile
                </button>
            </div>
        }
        </div>
    );
}