/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// react states
import { useEffect, useState } from 'react';
// database
import { useQuery } from '@tanstack/react-query';
import { db } from '../../Firebase/firebase';
import { collection, query, getDocs, limit, where, startAfter } from 'firebase/firestore';


const fetchGameReviews = async(game_id, limitCount=10, lastVisible=null)=>{
    if(!game_id){
        throw new Error("No Game reviews exist.");
    }

    let revQuery;
    if(lastVisible){
        revQuery = query(
            collection(db, 'GameReviews'), 
            where('gameID', '==', game_id),
            limit(limitCount),
            startAfter(lastVisible) // Start after the last visible document
        );
    }else{
        revQuery = query(
            collection(db, 'GameReviews'), 
            where('gameID','==',game_id),
            limit(limitCount)
        );
    }

    const revSnaps = await getDocs(revQuery);

    // console.log('Fetched Documents:', revSnaps.docs.length);

    const reviews = revSnaps.docs.map((revDoc) => ({
        id: revDoc.id, 
        ...revDoc.data(), 
        date: revDoc.data().date.toDate().toDateString()
    }));


    const lastVisibleDoc = revSnaps.docs[revSnaps.docs.length - 1];

    return { reviews, lastVisibleDoc };
    // return revSnaps.docs.map((revDoc)=>({'id':revDoc.id, ...revDoc.data(), 
    //     'date':revDoc.data().date.toDate().toDateString()
    // }))

}


export const useGetGameReviews = (gameID)=>{

    const [lastVisible, setLastVisible] = useState(null);
    const [allReviews, setAllReviews] = useState([]);
    const [limitCount, setLimitCount] = useState(10);
    const [hasMore, setHasMore] = useState(true);

    const { data, isFetching, refetch, error } = useQuery({
        queryKey: ['reviews', gameID, lastVisible],
        queryFn: () => fetchGameReviews(gameID, limitCount, lastVisible),
        enabled: !!gameID,
    });

    useEffect(()=>{
        if (data && data.reviews.length > 0) {
            setAllReviews((prevReviews) => [...prevReviews, ...data.reviews]);
            setLastVisible(data.lastVisibleDoc);
            setLimitCount(15); // After the first fetch, setting limit to 15 for subsequent fetches
        
            // Check if the number of reviews fetched is less than the limit, indicating no more reviews.
            if (data.reviews.length < limitCount) {
                setHasMore(false);
            }
        }else if (data && data.reviews.length === 0) {
            setHasMore(false); // No more reviews to load
        }
    },[data])

    // console.log('Data returned by useQuery:', data);
    // console.log('Error returned by useQuery:', error);

    // To fetch more reviews
    const fetchMoreReviews = () => {
        refetch(); 
    };

    return { reviews: allReviews, isFetching, fetchMoreReviews, error, hasMore };

    // return useQuery({
    //     queryKey:['reviews', gameID],
    //     queryFn: () => fetchGameReviews(gameID),
    //     enabled: !!gameID
    // })
}