/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './fullReviewPage.css';
// react states
import { useEffect, useState } from 'react';
// react redux
import { useSelector } from 'react-redux';
import { selectUser } from '../../../Features/userSlice';
// react router dom
import { useParams, Link, useNavigate } from 'react-router-dom';
// custom hooks
import { useGetUserReview } from '../../../Hooks/GameReviews/useGetUserReview';
import { useGameDetailsFetch } from '../../../Hooks/useGameDetailsFetch';
import { useGetUserProfile } from '../../../Hooks/useGetUserProfile';
import { useGiveReactions } from '../../../Hooks/Reactions/useGiveReactions';
// custom components
import Sharing from '../../Sharing/sharing';
import DiscussionArea from '../../DiscussionAreaComponents/discussionArea';
// mui components
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CommentIcon from '@mui/icons-material/Comment';


export default function FullReviewPage(){
    const { review_id } = useParams();

    // To get the currently signed in user
    const signed_in_user = useSelector(selectUser);

    // To get the review
    const { reviewDetails } = useGetUserReview(review_id);

    // To get the game which is reviewed
    const { fetchingGame, gameDetails } = useGameDetailsFetch(reviewDetails !== null ? reviewDetails.gameID : null);
    
    // To fetch the userDetails of the user who wrote the review
    const { getProfileDetails, userData, loadingUser } = useGetUserProfile();
    useEffect(()=>{
        if(reviewDetails !== null){
            getProfileDetails(reviewDetails.userID);
        }
    },[reviewDetails]);

    // To give and to get the reactions
    const { prevReaction, tempLikes, tempDislikes,
        handleClickLike, handleClickDislike } = useGiveReactions(
        'review',
        review_id,
        signed_in_user !== null ? signed_in_user.uid : null
    );

    // To share the review
    const [startSharingRev, setStartSharingRev] = useState(false);
    const openReviewSharingDialog = ()=>{
        setStartSharingRev(true);
    }
    const closeReviewSharingDialog = ()=>{
        setStartSharingRev(false);
    }

    // To navigate to gamepage & user profile
    const fullReviewNavigate = useNavigate();
    const navigateToMainGamePage = ()=>{
        if(reviewDetails !== null)
            fullReviewNavigate(`/game_page/${reviewDetails.gameID}`,{
                gameid: reviewDetails.gameID
            })
        else
            return;
    }

    const navigateToReviewUserProfile = ()=>{
        if(reviewDetails !== null){
            fullReviewNavigate(`/user_profile/${reviewDetails.userID}`)
        }else{
            return;
        }
    }

    // To open the reply section of the review
    const [openCommentSection, setOpenCommentSection] = useState(true);
    const toggleCommentSection = ()=>{
        if(openCommentSection === true)
            setOpenCommentSection(false);
        else if(openCommentSection === false)
            setOpenCommentSection(true);
    }

    // To get the count of number of comments on the full review
    const [fullRevCommentsCount, setFullRevCommentsCount] = useState(0);

    return(
        <div className="full-review-container">
            {
                startSharingRev === true &&
                <Sharing
                    // type, sharingLink, extraText, startSharing, closeSharingDialog
                    type={'review'}
                    sharingLink = {`https://thegamerbroz.com/full_review/${review_id}`}
                    extraText={reviewDetails!==null ? reviewDetails.review_text.substring(0,25) : 'Check out this review on The Gamer Bros'}
                    startSharing={startSharingRev}
                    closeSharingDialog={closeReviewSharingDialog}
                />
            }
            {
                loadingUser === false && userData &&
                <div className="review-user-details-container">
                    <div className="review-user-details-pfp-box"
                         onClick={()=>navigateToReviewUserProfile()}
                    >
                    {
                        userData &&
                        userData.profile_picture === null &&
                        <p>{userData.Name.substring(0,1).toUpperCase()}</p>
                    }
                    {
                        userData && userData.profile_picture !== null &&
                        <img src={userData.profile_picture} alt=""/>
                    }
                    </div>
                    <button className='review-user-btn'
                            onClick={()=>navigateToReviewUserProfile()}
                    >
                    {
                        userData && `${userData.Name}`
                    }
                    </button>
                    <p className='review-user-details-container-p'>review of</p>
                </div>
            }
            {
                loadingUser === true &&
                <div className="full-review-user-loaders">
                    <div className="review-user-details-pfp-loader"></div>
                    <div className="review-user-btn-loader"></div>
                </div>
            }
            {
                fetchingGame === true &&
                <div className="full-review-game-details-loader">

                </div>
            }
            {
                fetchingGame === false && gameDetails &&
                <div className="full-review-game-details-container">
                {
                    gameDetails &&
                    <div className="full-review-game-pfp-box"
                         onClick={()=>navigateToMainGamePage()}
                    >
                    {
                        gameDetails.thumbnail_image !== null &&
                        <img src={gameDetails.thumbnail_image} alt={`${gameDetails.name}`}/>
                    }
                    {
                        (gameDetails.thumbnail_image === null || gameDetails.thumbnail_image === undefined) &&
                        <p className='review-user-details-container-p'>{gameDetails.name}</p>
                    }
                    </div>
                }
                    <div className="full-review-game-details-txts">
                    {
                        gameDetails &&
                        <button className='full-review-game-name'
                                onClick={()=>navigateToMainGamePage()}
                        >
                            {gameDetails.name}
                        </button>
                    }
                    {reviewDetails && <p className='full-game-review-score'>{reviewDetails.score}/10</p>}
                    </div>
                </div>
            }
            {
                reviewDetails &&
                <div className="full-game-review-text"
                     dangerouslySetInnerHTML={{
                        __html:reviewDetails.review_text
                     }}
                >

                </div>
            }
            <div className="full-review-action-container">
                <button className="reaction-rev-area-btn"
                        onClick={()=>{
                            reviewDetails &&
                            handleClickLike(reviewDetails);
                        }}
                >
                    <ThumbUpIcon style={{ 'fontSize':'18px', 'color':(prevReaction.length > 0 && prevReaction[0]==='L') ? '#FFE74C' : '#F8F8F8' }}/>
                    <p style={{ 'color':(prevReaction.length > 0 && prevReaction[0]==='L') ? '#FFE74C' : '#F8F8F8' }}>{tempLikes}</p>
                </button>
                <button className="reaction-rev-area-btn"
                        onClick={()=>handleClickDislike()}
                >
                    <ThumbDownIcon style={{ 'fontSize':'18px', 'color':(prevReaction.length > 0 && prevReaction[0]==='D') ? '#FFE74C' : '#F8F8F8' }}/>
                    <p style={{ 'color':(prevReaction.length > 0 && prevReaction[0]==='D') ? '#FFE74C' : '#F8F8F8' }}>{tempDislikes}</p>
                </button>
                <button className='rev-reply-btn'
                        onClick={()=>toggleCommentSection()}
                >
                    <CommentIcon style={{ 'marginRight':'5px', 'fontSize':'20px', 'color':(openCommentSection === true) ? '#FFE74C' : '#F8F8F8' }}/>
                    <p style={{ 'textAlign':'left', 'color':(openCommentSection === true) ? '#FFE74C' : '#F8F8F8' }}>{fullRevCommentsCount}</p>
                </button>
                <button className='rev-reply-btn'
                        onClick={()=>openReviewSharingDialog()}
                >
                    Share
                </button>
                {
                    signed_in_user !== null &&
                    reviewDetails !== null && 
                    <Link to={'/report'} style={{ textDecoration:'none' }}
                        state={{
                            'item': reviewDetails,
                            'reporter': signed_in_user,
                            'type':'review'
                        }}
                    >
                        <button className='rev-reply-btn'>
                            Report
                        </button>
                    </Link>
                }
            </div>
            <div className="review-comments-area">
            {
                openCommentSection === true &&
                review_id !== null &&
                reviewDetails !== null &&
                <DiscussionArea discussionID={review_id} 
                                // gameID={discussionTopic.gameID}
                                taggedGames={[reviewDetails.gameID]}
                                section={'review'}
                                commentsCounter={setFullRevCommentsCount}
                                fullDoc={reviewDetails}
                />
            }
            </div>
        </div>
    )
}