// styles
import './user_signup.css';
// react states
import { useState } from 'react';
// custom hook(s)
import { useSignup } from '../../../Hooks/useSignup.js';
// mui icons
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function UserSignUp({ navPrompt }){

    const welcomeMessage = "Hello there! Welcome. Let’s start by knowing you….";

    // Hook to use for logging in
    const { error, status, signingUp, createUserAccount } = useSignup();

    // routing
    const loginNavigate = useNavigate();

    // To toggle the visibility of the password
    const [visible, setVisible] = useState(false);
    const toggleVisibility = ()=>{
        if(visible === false){
            setVisible(true);
        }else{
            setVisible(false);
        }
    }

    // To handle the submit events
    const [mail, setMail] = useState('');
    const [pass, setPass] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    // const [displayName, setDisplayName] = useState('');
    const [passError, setPassError] = useState(null);
    
    const handleLogin = (e)=>{
        e.preventDefault();
        // console.log({
        //     // displayName,
        //     mail,
        //     pass,
        //     confirmPass
        // })
        if(confirmPass !== pass){
            setPassError("Both passwords don't match. Please make sure the passwords are same");
            return;
        }

        // logUserIn(mail, pass);
        createUserAccount('Gamer', mail, pass);
    }

    const navigateToLogin = ()=>{
        loginNavigate('/user_auth');
    }

    return(
        <div className="user-signup-container"
            //  style={{ 'width':`${(600/window.innerWidth)*100}%`,
            //         //   'height':`${(600/window.innerHeight)*100}%`
            //         }}
        >
            <h4>{welcomeMessage}</h4>
            <form className='user-signup-input-form'
                  onSubmit={(e)=>handleLogin(e)}
            >
                {/* <input 
                    type="text"
                    required
                    placeholder='Chose a display name'
                    onChange={(e)=>setDisplayName(e.target.value)}
                    value={displayName}
                /> */}
                <input 
                    type="email"
                    required
                    placeholder='Enter your email'
                    onChange={(e)=>setMail(e.target.value)}
                    value={mail}
                />
                <div className="signup-password-area">
                    <input 
                        className='signup-password-input'
                        type={ visible === true ? "text" : "password"}
                        required
                        placeholder='Enter your password'
                        onChange={(e)=>{
                            setPassError(null);
                            setPass(e.target.value)
                        }}
                        value={pass}
                    />
                    <div className="visibility-container"
                         onClick={()=>toggleVisibility()}
                    >
                    {
                        (visible === false) ? <VisibilityOffIcon style={{ 'color':'#f8f8f8' }}/>
                        : <VisibilityIcon style={{ 'color':'#f8f8f8' }}/>   
                    }
                    </div>
                </div>
                <div className="signup-password-area">
                    <input 
                        className='signup-password-input'
                        type={ visible === true ? "text" : "password"}
                        required
                        placeholder='Re-enter your password'
                        onChange={(e)=>{
                            setPassError(null);
                            setConfirmPass(e.target.value)
                        }}
                        value={confirmPass}
                    />
                    <div className="visibility-container"
                         onClick={()=>toggleVisibility()}
                    >
                    {
                        (visible === false) ? <VisibilityOffIcon style={{ 'color':'#f8f8f8' }}/>
                        : <VisibilityIcon style={{ 'color':'#f8f8f8' }}/>   
                    }
                    </div>
                </div>
                <p style={{
                    'textAlign':'left','marginBottom':'10px', 'color':'red', 'fontSize':'14px' }}>{passError}</p>
                {
                    signingUp !== true && <button className='cta'>
                                            Create account
                                          </button>
                }
                {
                    signingUp === true &&
                    <>
                        <CircularProgress size={24} style={{ 'color':'#FFE74C', 'marginBottom':'30px' }}/>
                        <p style={{ 'color':'#FFE74C', 'fontSize':'14px',
                                    'marginTop':'10px' 
                                 }}>{status}</p>
                    </>
                }
            </form>
            { error && <p>{error}</p> }
            {
                signingUp !== true && 
                <div className="signup-other-option-prompt">
                    <p>Already have an account ? </p>
                    <h4 onClick={()=>navigateToLogin()}>Login</h4>
                </div>
            }
        </div>
    )
}