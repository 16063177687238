/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './user_login.css';
// custom component(s)
import LoginForm from './LoginFormComponent/loginForm';

export default function UserLogin(){

    const welcomeMessage = "Welcome to The Gamer Bros!";

    return(
        <div className="user-login-container">
            <p className='login-welcome-msg'>{welcomeMessage}</p>
            <LoginForm/>
        </div>
    )
}